import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  media: {
    small: (css: string) => `@media(max-width: 575px) { ${css} }`,
    medium: (css: string) => `@media(min-width: 576px) and (max-width: 1200px) { ${css} }`,
    large: (css: string) => `@media(min-width: 1201px) { ${css} }`,
    print: (css: string) => `@media print { ${css} }`,
  },
  colors: {
    main: '#00E09F',
    mainTransparent: '#00E09FEE',
    black: '#2a2a2a',
    ltGray: '#dee2e6',
  },
  font: {
    family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    normalWeight: 400,
    boldWeight: 900,
  },
  borderRadius: '4px',
  border: '1px solid #dee2e6',
  borderLight: '1px solid #efefef',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
  insetBoxShadow: '0 0 8px -5px rgba(0, 0, 0, 0.55) inset',
  transitions: {
    in: '.35s',
    out: '.15s',
  },
  // UI Elements
  navWidth: '75px',
}

export default theme;