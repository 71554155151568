import React, { useContext } from 'react';

import { IsElectronContext, IIsElectronContextValue } from './_context/IsElectron';

import CSSReset from './_styled/global/Reset';
import GlobalStyles from './_styled/global/GlobalStyles';
import Draggable from './_styled/electron/Draggable';

const AppStyles: React.FC = () => {
  const { isElectron } = useContext<IIsElectronContextValue>(IsElectronContext);
  console.log({ isElectron })
  return (
    <>
      <CSSReset />
      <GlobalStyles />
      { isElectron && <Draggable /> }
    </>
  )
}

export default AppStyles;