/* 
    Context that determines whether environment is Electron which is useful
    for many reasons (i.e., don't show the product page on the Electron app,
    don't render the <Draggable /> div on the web app, etc.)
*/

import React, { Component, createContext } from 'react';

export interface IIsElectronContextValue {
  isElectron: boolean
}

export const IsElectronContext: React.Context<IIsElectronContextValue> = createContext({} as IIsElectronContextValue);

interface IState {
  isElectron: boolean
}

export default class IsElectronProvider extends Component<{}, IState> {
  state = {
    isElectron: false,
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isElectron = userAgent.indexOf(' electron/') > -1;
    this.setState({ isElectron });
  }

  render() {
    return (
      <IsElectronContext.Provider
        value={{
          isElectron: this.state.isElectron
        } as IIsElectronContextValue }
      >

        { this.props.children }
        
      </IsElectronContext.Provider>
    )
  }
}

// HOC for providing passed props and IsElectron context made available by this file to a ComponentType (React.Component or React.FC)
// affords following API: withIsElectron(YourComponentHere)
export const withIsElectron = <Props extends {}>(WrappedComponent: React.ComponentType<Props>): React.FC<Props> => {
  return function Contextualized(props: Props) {
    return (
      <IsElectronProvider>
        <IsElectronContext.Consumer>
          
          { (value: IIsElectronContextValue) => (
            <WrappedComponent
              { ...props as Props }
              isElectron={ value.isElectron }
            />
          )}

        </IsElectronContext.Consumer>
      </IsElectronProvider>
    )
  }
}