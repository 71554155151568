import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Nav from './Nav';
import Login from './Login';

interface IProps {

}

const MainRouter: React.FC<IProps> = (props: IProps) => {

  return (
    <Router>

      <Nav />

      <Switch>
        <Route exact path="/login" component={ Login } />
      </Switch>
    </Router>
  )
}

export default MainRouter;