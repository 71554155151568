import styled from 'styled-components';

const Draggable = styled.div`
  -webkit-app-region: drag
  height: 50px;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export default Draggable;