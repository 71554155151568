import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    
    * {
      box-sizing: border-box;
    }
    
    body {
      * {

        /* System Fonts */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

      }
    }
  }
`;

export default GlobalStyles;