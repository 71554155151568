import React, { 
  // useContext
} from 'react';

const Nav: React.FC<{}> = (props: {}) => {
  return (
    <nav>Nav</nav>
  )
}

export default Nav;