import React from 'react';
import IsElectronProvider from './_context/IsElectron';
import { ThemeProvider } from 'styled-components';
import theme from './_styled/global/theme';
import AppStyles from './AppStyles';

import Router from './_components/navigation/Router';

const App: React.FC<{}> = () => (
  <>
    <IsElectronProvider>

      <AppStyles />
      <ThemeProvider theme={ theme }>
        <>

          <Router />
          
        </>
      </ThemeProvider>

    </IsElectronProvider>
  </>
)

export default App;